/**
 * Basket
 */

const BASKET_SEL = '[data-basket]';
const TOGGLE_SEL = '[data-basket-toggle]';
const PRODUCTS_SEL = '[data-basket-products]';
const NOPRODUCTS_SEL = '[data-basket-noproducts]';
const COUNT_SEL = '[data-basket-count]';
const UPDATE_SEL = '[data-basket-update]';
const SUBMIT_SEL = '[data-basket-submit]';

function init() {
  const basket = document.querySelector(BASKET_SEL);
  if (basket) {
    toggleBasket(basket)
    updateProduct()
    updateProductList()
  }
}

function toggleBasket(basket) {
  const toggles = document.querySelectorAll(TOGGLE_SEL);
  toggles.forEach((toggle) => {
    toggle.addEventListener('click', (event) => {
      const shouldReveal = basket.getAttribute('aria-expanded') === 'false';
      basket.setAttribute('aria-expanded', shouldReveal ? 'true' : 'false')
      toggles.forEach((toggle)=> toggle.setAttribute('aria-expanded', shouldReveal ? 'true' : 'false'))
    });
  });
}

function updateProduct() {
  const body = document.querySelector('body');
  body.addEventListener("click",(event) => {
    if (event.target && event.target.matches(UPDATE_SEL)) {
      const route = event.target.dataset.basketUpdate
      fetch(route)
        .then(response => {
          return response.json();
        })
        .then(() => updateProductList())
        .catch((err) => console.warn('Something went wrong.', err))
    }
  });
}

function updateProductList() {
  const productsContainer = document.querySelector(PRODUCTS_SEL);
  const route = productsContainer.dataset.basketProducts
  let total
  fetch(route)
    .then(response => {
      return response.text();
    })
    .then(data => {
      productsContainer.innerHTML = data
      total = productsContainer.querySelectorAll('article').length
      updateTotal(total)
    })
    .catch((err) => console.warn('Something went wrong.', err))
}

function updateTotal(total) {
  const counters = document.querySelectorAll(COUNT_SEL);
  const noProducts = document.querySelector(NOPRODUCTS_SEL);
  const submit = document.querySelector(SUBMIT_SEL);
  submit.style.display = total > 0 ? 'inline-flex' : 'none'
  noProducts.style.display = total > 0 ? 'none' : 'block'
  counters.forEach((counter)=> {
    counter.textContent = total
    counter.dataset.state = total > 0 ? 'visible' : 'hidden'
    counter.dataset.animate = 'start'
    setTimeout(update, 1000);
    function update() {
      counter.dataset.animate = 'stop'
    }
  })
}

export default { init }
