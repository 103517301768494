/**
 * Pagination : ajax load more button
 */

const BTN_SEL = '[data-loadmore]'
const CONTAINER_SEL = '[data-loadmore-container]'
const CURRENT_SEL = '[data-loadmore-current]'
const TOTAL_SEL = '[data-loadmore-total]'
const PERCENT_SEL = '[data-loadmore-percent]'
const STEP = 9


//inits the initOffset var based on the original offset param in the URL
// let initOffset;
// if(!isNaN(URL_OFFSET)){
//   initOffset = URL_OFFSET;
// } else {
//   initOffset = 0;
// }
//
// let currentOffset = initOffset;
// let offset = 0;

function init() {

  const loadMoreBtn = document.querySelector(BTN_SEL);
  
  if (loadMoreBtn) {

    const url = new URL(document.URL);
    const url_params = new URLSearchParams(location.search);
    const url_offset = parseInt(url_params.get('offset'));
    const container = document.querySelector(CONTAINER_SEL);
    const route = loadMoreBtn.dataset.loadmoreRoute;
    const step = STEP;
    let offset = url_offset || 0


    let nbTotal = parseInt(loadMoreBtn.dataset.loadmoreTotal);
    let nbCurrent = (offset + step) < nbTotal ? (offset + step) : nbTotal;

    //if nbCurrent >=  nbTotal, the load more button is hidden
    if (nbCurrent >=  nbTotal) {
      loadMoreBtn.style.display = 'none';
    }

    //request for new elements
    const xmlhttp = new XMLHttpRequest();
    xmlhttp.onreadystatechange = function() {
      if (xmlhttp.readyState == XMLHttpRequest.DONE) {
        if (xmlhttp.status == 200) {
          container.innerHTML = container.innerHTML + xmlhttp.responseText;
          let nbCurrent = (offset + step) < nbTotal ? (offset + step) : nbTotal;
          updatePagination(nbCurrent, nbTotal);
          if (nbCurrent >=  nbTotal) {
            loadMoreBtn.style.display = 'none';
          }
        }
        //errors
        else if (xmlhttp.status == 400) {
          console.warn('xmlhttp error 400');
        }
        else {
          console.warn('xmlhttp other error than 200 was returned');
        }
      }
    };
    updatePagination(nbCurrent, nbTotal);
    loadMoreBtn.addEventListener('click', (event) => {
      offset = offset + step;
      loadMore(offset, step, url, route, xmlhttp);
    });
  }

}

function loadMore (offset, step, url, route, xmlhttp) {
  url.searchParams.set('offset', offset);
  history.pushState('', '', url);
  xmlhttp.open("GET", route + url.search, true);
  xmlhttp.send();
}

function updatePagination (current, total) {
  const currentEl = document.querySelector(CURRENT_SEL);
  const totalEl = document.querySelector(TOTAL_SEL);
  const percentEl = document.querySelector(PERCENT_SEL);
  const percent = current * 100 / total;
  currentEl.textContent = current < total ? current : total;
  totalEl.textContent = total;
  percentEl.style.width = `${percent}%`;
}

export default { init };
