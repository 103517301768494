/**
 * Main nav Dropdown open/close only for burger nav
 */

const DROPDOWN_ATTR = 'data-mainnav-item'
const DROPDOWN_SEL = '[data-mainnav-item]'


function init() {
  document.addEventListener('click', function () {
    if (event.target.hasAttribute(DROPDOWN_ATTR)) {
      event.stopPropagation();
      event.preventDefault();
      openDropdown(event.target)
    } else closeAll()
  })
}

function openDropdown(element) {
  const id = element.getAttribute('aria-controls')
  const panel = document.querySelector(`#${id}`)
  const shouldReveal = panel && panel.hidden
  closeAll()

  if (!panel) {
    return;
  }

  element.setAttribute('aria-expanded', shouldReveal ? 'true' : 'false')
  panel.hidden = !shouldReveal
  panel.addEventListener("click",function(event) {
    event.stopPropagation()
  })
}

function closeAll() {
  const dropdowns = document.querySelectorAll(DROPDOWN_SEL)
  dropdowns.forEach(function(element) {
    const id = element.getAttribute('aria-controls')
    const panel = document.querySelector(`#${id}`)
    element.setAttribute('aria-expanded', 'false')
    if (panel) {
      panel.hidden = true
    }
  })
}

export default { init }
