/**
 * Dropdown open/close
 */

const DROPDOWN_ATTR = 'data-dropdown';
const DROPDOWN_SEL = '[data-dropdown]';


function init() {
  document.addEventListener('click', (event)=> {
    if (event.target.hasAttribute(DROPDOWN_ATTR)) {
      event.stopPropagation();
      openDropdown(event.target)
    } else {
      closeAll();
    }
  })
}


function openDropdown(element) {
  const id = element.getAttribute('aria-controls')
  const panel = document.querySelector(`#${id}`);
  const shouldReveal = panel.hidden;
  let panelLastLink = panel.querySelectorAll('a', 'button');

  closeAll()
  element.setAttribute('aria-expanded', shouldReveal ? 'true' : 'false');
  panel.hidden = !shouldReveal;
  panel.addEventListener("click",function(event) {
    event.stopPropagation();
  })
  element.addEventListener('keydown', function(event) {
    if (event.shiftKey && event.keyCode === 9) {
      closeAll();
    }
  });
  if(panelLastLink.length > 0) {
    panelLastLink = panelLastLink[panelLastLink.length - 1];
    panelLastLink.addEventListener('keydown', function (event) {
      if (event.shiftKey && event.keyCode === 9) {
        // do noting if shift tab
      }
      else if (event.keyCode === 9) {
        closeAll();
      }
    });
  }
}

function closeAll() {
  const dropdowns = document.querySelectorAll(DROPDOWN_SEL);
  dropdowns.forEach(function(element) {
    const id = element.getAttribute('aria-controls')
    const panel = document.querySelector(`#${id}`);
    element.setAttribute('aria-expanded', 'false');
    panel.hidden = true;
  });
}

export default { init }