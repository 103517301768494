// ------
// Styles
// ------

import "normalize.css/normalize.css";
import "./styles/index.scss";


// ---------
// SVG icons
// ---------

import "./icons";

// -------
// Scripts
// -------

import "van11y-accessible-tab-panel-aria/dist/van11y-accessible-tab-panel-aria.min";

import basket from "./components/basket/basket.js";
import dropdown from "./components/dropdown/dropdown.js";
import expand from "./components/expand/expand.js";
import filters from "./components/filters/filters.js";
import foldsection from "./components/foldsection/foldsection.js";
import formfield from "./components/formfield/formfield.js";
import formselectmulti from "./components/formselectmulti/formselectmulti.js";
import formtrail from "./components/formtrail/formtrail.js";
import formtraillinks from "./components/formtraillinks/formtraillinks.js";
import header from "./components/header/header.js";
import infography from "./components/infography/infography.js";
import mainnav from "./components/mainnav/mainnav.js";
import map from "./components/map/map.js";
import pagination from "./components/pagination/pagination.js";
import scrolltop from "./components/scrolltop/scrolltop.js";
import slider from "./components/slider/slider.js";
import tabs from "./components/tabs/tabs.js";
import toggle from "./components/toggle/toggle.js";

// Run init function for all files when document is ready
if (document.readyState === "loading") {
  document.addEventListener("DOMContentLoaded", init);
} else {
  init();
}

// window.addEventListener('popstate', function (event) {
//   document.location.reload();
// });

function init() {
  basket.init()
  dropdown.init()
  expand.init()
  filters.init()
  foldsection.init()
  formfield.initPassword()
  formfield.initUpload()
  formselectmulti.init()
  formtrail.init()
  formtraillinks.init()
  header.init()
  infography.init()
  mainnav.init()
  map.init()
  pagination.init()
  scrolltop.init()
  slider.init()
  tabs.init()
  toggle.init()
}

