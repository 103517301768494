/**
 * Header open/close
 */

const BURGER_SEL = '[data-header-burger]';
const SEARCH_SEL = '[data-header-search]';
const SEARCH_BUTTON_SEL = '[data-header-searchbutton]';
const SEARCH_INPUT_SEL = '[data-header-search] input';



function init() {
  // close burger menu by default on small screen
  const burger = document.querySelector(BURGER_SEL);
  if (burger) {
    const panel = document.getElementById(burger.getAttribute('aria-controls'));
    if (window.matchMedia('(max-width: 1100px)').matches) {
      burger.setAttribute('aria-expanded', 'false')
      panel.setAttribute('aria-hidden', 'true')
    }
    burger.addEventListener("click",function() {
      toggleBurger(burger, panel);
    });
  }
}


function toggleBurger(burger, panel) {
  const root = document.getElementsByTagName( 'html' )[0];
  const shouldReveal = panel.getAttribute('aria-hidden') == 'true';
  burger.setAttribute('aria-expanded', shouldReveal ? 'true' : 'false')
  panel.setAttribute('aria-hidden', shouldReveal ? 'false' : 'true')
  if (shouldReveal) {
    root.classList.add('fixOverflow');
  } else {
    root.classList.remove('fixOverflow');
  }
}

const search = document.querySelector(SEARCH_SEL);
if (search) {

  const searchButton = document.querySelector(SEARCH_BUTTON_SEL);
  const searchInput = document.querySelector(SEARCH_INPUT_SEL);

  searchButton.addEventListener("click",function(event) {
    if (searchButton.getAttribute('type') === 'button') {
      event.preventDefault()
      searchButton.setAttribute('type', 'submit')
      search.setAttribute('aria-expanded', 'true')
      searchInput.focus()
    } else if (searchButton.getAttribute('type') === 'submit' && searchInput.value == '') {
      event.preventDefault()
      searchButton.setAttribute('type', 'button')
      search.setAttribute('aria-expanded', 'false')
    }
  });

  document.addEventListener('click', function() {
    if (search.contains(event.target) ) return;
    search.setAttribute('aria-expanded', 'false')
    searchButton.setAttribute('type', 'button')
  }, false)

}



export default { init }
