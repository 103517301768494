/**
 * Map with leafletjs lib
 */

import "leaflet/dist/leaflet.css";
import L from "leaflet";

const MAP_SEL = '[data-map]';
const API_KEY = 'pk.eyJ1IjoiY3NhcmRhIiwiYSI6ImNrbXo0Nm9wYzBhOG8yd28wcjFudTc5cmUifQ.SGZNsfeKGJjRVfPxdljIlw'
const ICON_SVG = `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M9.548 0c7.07 0 11.652 7.469 8.564 13.743C16.62 16.929 11.64 21.71 9.548 24c-2.19-2.49-6.87-6.871-8.465-10.158C-2.202 7.668 2.378 0 9.548 0zm0 3.585c3.286 0 5.975 2.689 5.975 5.975 0 3.286-2.689 5.975-5.975 5.975-3.286 0-5.975-2.689-5.975-5.975 0-3.286 2.69-5.975 5.975-5.975z" fill="#BB1C81"/></svg>`


function init() {

  const maps = document.querySelectorAll(MAP_SEL);
  if (maps) {
    maps.forEach(map => initMap(map))
  }

}

function initMap(map) {

  // get marker data
  const markerData = JSON.parse(map.dataset.marker)
  const markerTitle = markerData.title
  const markerTooltip = markerData.tooltip
  const markerAddress = markerData.address
  // const encodedAddress = encodeURI(markerData.address)

  if(markerData.lat && markerData.lng) {
    createMap([markerData.lat, markerData.lng], markerTitle, markerTooltip, markerAddress, map)
  }
  // else {
  //   fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${encodedAddress}.json?access_token=${API_KEY}`)
  //     .then(response => response.json())
  //     .then(data => createMap([data.features[0].geometry.coordinates[1], data.features[0].geometry.coordinates[0]], markerTitle, markerAddress, map))
  //     .catch(error => console.error(error))
  // }

}

function createMap(latLng, markerTitle, markerTooltip, markerAdress, map) {
  // create map
  const myMap = L.map(map).setView(latLng, 17);
  const mapLayer = L.tileLayer('https://{s}.tile.osm.org/{z}/{x}/{y}.png',{
  // const mapLayer = L.tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}',{
    id: 'mapbox/streets-v11',
    accessToken: API_KEY
  });
  myMap.addLayer(mapLayer)

  // create marker & popup
  const icon = L.icon( {
    iconUrl: 'data:image/svg+xml;base64,' + btoa(ICON_SVG),
    iconSize: [19, 24],
    popupAnchor: [0, -10],
  } );
  const popupContent = `${markerTooltip || ''}`
  const marker = L.marker(latLng, { icon: icon }).addTo(myMap);
  if (markerTooltip) {
    marker.bindPopup(popupContent).openPopup();
  }
}


export default { init }
