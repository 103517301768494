/**
 * FormTrail : count how many checkboxes are checked
 */

const FORM_SEL = '[data-formtraillinks]';
const QUESTION_SEL = '[data-formtraillinks-question]';
const DROPDOWN_SEL = '[data-formtraillinks-dropdown]';
const SELECT_SEL = '[data-formtraillinks-select]';
let default_label;

function init() {

  const form = document.querySelector(FORM_SEL);
  if (form) {
    const questions = form.querySelectorAll(QUESTION_SEL);
    const selects = form.querySelectorAll(SELECT_SEL);
    default_label = form.querySelector(DROPDOWN_SEL).textContent
    questions.forEach((question) => {
      updateChoice(question, form)
    });
    selects.forEach((select) => {
      select.addEventListener('click', ()=> {
        const current_question = select.closest(QUESTION_SEL)
        const siblings = current_question.querySelectorAll('li')
        const next_question = current_question.nextElementSibling;
        next_question.querySelectorAll(`li`).forEach((sibling) => sibling.dataset.selected = false )
        const next_next_question = next_question.nextElementSibling;
        if(next_next_question) {
          next_next_question.querySelectorAll(`li`).forEach((sibling) => sibling.dataset.selected = false )
          updateLabel(next_next_question)
        }
        siblings.forEach((sibling) => sibling.dataset.selected = false )
        select.parentElement.dataset.selected = true
        updateChoice(current_question, form)
      });
    });
  }

}

function updateChoice(question, form) {
  const selected_option = question.querySelector(`[data-selected = true]`)
  if (selected_option) {
    updateLabel(question)
    const current_id = selected_option.id
    const selected_value = form.querySelector(`[aria-labelledby=${ current_id }]`)
    if (selected_value) {
      updateValues(current_id, selected_value, form)
    }
  }
}

function updateLabel(question) {
  const selected_option = question.querySelector(`[data-selected = true]`)
  const selected_option_text = selected_option ? selected_option.textContent : default_label
  const label = question.querySelector(DROPDOWN_SEL)
  label.textContent = selected_option_text
  question.hidden = false
}
function updateValues(current_id, selected_value, form) {
  const new_question = selected_value.closest(QUESTION_SEL)
  const selected_option_values = form.querySelectorAll(`[aria-labelledby=${ current_id }]`)
  const unselected_options_values = new_question.querySelectorAll(`li:not([aria-labelledby=${ current_id }])`)
  new_question.hidden = false
  unselected_options_values.forEach((option) => option.hidden = true )
  selected_option_values.forEach((option) => option.hidden = false )
  updateLabel(new_question)
}


export default { init }
