/**
 * FormSelectMulti : count how many checkboxes are checked
 */

const SELECT_SEL = '[data-formselectmulti]';
const PLACEHOLDER_SEL = '[data-formselectmulti-placeholder]';
const COUNTER_SEL = '[data-formselectmulti-counter]';

function init() {
  const select = document.querySelector(SELECT_SEL);
  if (select) {
    const selects = document.querySelectorAll(SELECT_SEL);
    selects.forEach(select => {
      const options = select.querySelectorAll('input');
      update(select)
      options.forEach(option => {
        option.addEventListener('change', (event)=> {
          update(select)
        })
      })

    })

  }
}

function update(select) {

  const placeholder_el = select.querySelector(PLACEHOLDER_SEL);
  const counter_el = select.querySelector(COUNTER_SEL);
  const placeholder_text_default = placeholder_el.dataset.formselectmultiPlaceholder
  let placeholder_text = placeholder_text_default
  counter_el.style.opacity = 0
  placeholder_el.style.opacity = 0.7

  const numberChecked = select.querySelectorAll('input:checked').length - 1
  counter_el.textContent = numberChecked > 0 ? `+${numberChecked}` : null
  counter_el.style.opacity = numberChecked > 0 ? 1 : 0

  const oneIsChecked = select.querySelector('input:checked + label')
  placeholder_text =  oneIsChecked ? select.querySelector('input:checked + label').textContent : placeholder_text_default
  placeholder_el.textContent = placeholder_text
  placeholder_el.style.opacity = oneIsChecked !== null ? 1 : 0.7
}

export default { init }
