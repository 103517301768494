/**
 * Filters auto submit on input change
 */

const FILTERS_SEL = '[data-filters]';
const TOGGLE_SEL = '[data-filters-toggle]';

function init() {

  const filters = document.querySelector(FILTERS_SEL);
  if (filters) {
    const inputs = filters.querySelectorAll('input');
    inputs.forEach((input) => {
      input.addEventListener('change', (event) => {
        const form = input.closest('form');
        form.submit()
      });
    })

    const toggle = filters.querySelector(TOGGLE_SEL);
    const panel = document.getElementById(toggle.getAttribute('aria-controls'));
    if (window.matchMedia('(max-width: 1100px)').matches) {
      toggle.setAttribute('aria-expanded', 'false')
      panel.setAttribute('aria-hidden', 'true')
    }
    toggle.addEventListener("click",function() {
      togglePanel(toggle, panel);
    });
  }
}

function togglePanel(toggle, panel) {
  const root = document.getElementsByTagName( 'html' )[0];
  const shouldReveal = panel.getAttribute('aria-hidden') == 'true';
  toggle.setAttribute('aria-expanded', shouldReveal ? 'true' : 'false')
  panel.setAttribute('aria-hidden', shouldReveal ? 'false' : 'true')
  if (shouldReveal) {
    root.classList.add('fixOverflow');
  } else {
    root.classList.remove('fixOverflow');
  }
}

export default { init }
