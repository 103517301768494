const UPLOAD_SEL = '[type="file"]';
const PLACEHOLDER_SEL = '[data-file-placeholder]';
const PASSWORD_SEL = '[type="password"]';
const PASSWORD_SHOW_SEL = '[data-password-show]';
const PASSWORD_SHOW_ATTR = 'data-password-show';


function initPassword() {
  const password = document.querySelector(PASSWORD_SHOW_SEL);
  if (password) {
    const passwordFields = document.querySelectorAll(PASSWORD_SEL);
    document.addEventListener('click', function() {
      if (event.target.hasAttribute(PASSWORD_SHOW_ATTR)) {
        event.stopPropagation();
        const field = event.target.previousElementSibling
        field.type = 'text'
      } else {
        passwordFields.forEach((passwordField)=> { passwordField.type = 'password' })
      }
    })
  }
}

function initUpload() {
  const upload = document.querySelector(UPLOAD_SEL);
  if (upload) {
    const uploadFields = document.querySelectorAll(UPLOAD_SEL);
    uploadFields.forEach((field)=> {
      const placeholder = field.parentNode.querySelector(PLACEHOLDER_SEL)
      let text = ''
      field.addEventListener('change', function(){
        text = field.value.replace(/^.*[\\\/]/, '');
        placeholder.textContent = text
      })
    })
  }
}

export default { initPassword, initUpload }
