/**
 * Slider with tiny slider lib
 */

import { tns } from "tiny-slider/src/tiny-slider"
const SLIDER_SEL = '[data-slider]';


function init() {
  const sliders = document.querySelectorAll(SLIDER_SEL);
  if (sliders) {
    initSliders(sliders)
  }
}

function initSliders(sliders) {
  sliders.forEach((slider) => {
    const tnsConf = {
      container: slider,
      nav: false,
      navPosition: 'bottom',
      loop: false,
      mouseDrag: true,
      gutter: 30,
      slideBy: 'page'
    }
    if (slider.dataset.items) {
      const responsive = (slider.dataset.items).split(',')
      tnsConf.items = responsive[0]
      let tablet = responsive[1]
      let desktop = responsive[2]
      if (tablet == 0) {
        tablet = {
          disable : true,
        }
      } else {
        tablet = {
          items: responsive[1],
        }
      }
      if (desktop == 0) {
        desktop = {
          disable : true,
        }
      } else {
        desktop = {
          items: responsive[2],
        }
      }
      tnsConf.responsive = {
        700: tablet,
        1100: desktop
      }
    }
    if (slider.dataset.fixedwidth) {
      const responsive = (slider.dataset.fixedwidth).split(',')
      tnsConf.fixedWidth = parseFloat(responsive[0])
      tnsConf.responsive = {
        700: {
          fixedWidth: parseFloat(responsive[1]),
        },
        1100: {
          fixedWidth: parseFloat(responsive[2]),
        }
      }
    }
    if (slider.dataset.mode === 'gallery') {
      tnsConf.mode = slider.dataset.mode
      tnsConf.nav = true
      tnsConf.controls = false
      tnsConf.gutter = 0
      tnsConf.loop = true
    }
    if (slider.dataset.autoplay) {
      tnsConf.autoplay = true
    }
    if (slider.dataset.startindex) {
      tnsConf.startIndex = slider.dataset.startindex
    }
    const sliderTns = tns(tnsConf);

    if (slider.dataset.nav === 'preview') {
      sliderTns.events.on('transitionStart', updateLabel);
    }

    if (slider.dataset.class) {
      slider.parentNode.parentNode.classList.add(`tns-class--${slider.dataset.class}`);
    }

  });

}

export default { init }
