/**
 * FormTrail : count how many checkboxes are checked
 */

const FORM_SEL = '[data-formtrail]';
const QUESTION_SEL = '[data-formtrail-question]';
const OPTION_SEL = '[data-formtrail-option]';
const BACK_SEL = '[data-formtrail-back]';

function init() {

  const form = document.querySelector(FORM_SEL);
  if (form) {
    const options = form.querySelectorAll(OPTION_SEL);
    const backBtn = form.querySelector(BACK_SEL);
    let currentStep = 1

    // display question 1 by default
    displayQuestion(currentStep)

    // add back button event
    backBtn.addEventListener('click', ()=> {
      currentStep = currentStep > 0 ? currentStep - 1 : 1
      displayQuestion(currentStep)
    })

    // add each option event
    options.forEach((option) => {
      option.addEventListener('click', ()=> {
        const currentQuestion = option.closest(QUESTION_SEL)
        currentStep = parseFloat(currentQuestion.dataset.formtrailQuestion) + 1
        const siblings = currentQuestion.querySelectorAll('li')
        // pass clicked option in selected and siblings in not selected
        siblings.forEach((sibling) => sibling.dataset.selected = false )
        option.parentElement.dataset.selected = true
        // map good options in next question to display it
        const currentId = option.parentElement.id
        if (currentId) {
          displayQuestion(currentStep)
          updateValues(currentId, form)
        }
      });
    });
  }
}


function displayQuestion(currentStep) {
  const form = document.querySelector(FORM_SEL);
  const questions = form.querySelectorAll(QUESTION_SEL);
  const backBtn = form.querySelector(BACK_SEL);
  questions.forEach((question, index) => question.hidden = index + 1 > currentStep)
  if(currentStep > 1) {
    backBtn.hidden = false
  } else {
    backBtn.hidden = true
  }
}

function updateValues(currentId, form) {
  const selectedValue = form.querySelector(`[aria-labelledby=${ currentId }]`)
  const question = selectedValue.closest(QUESTION_SEL)
  const selectedOptionValues = form.querySelectorAll(`[aria-labelledby=${ currentId }]`)
  const unselectedOptionsValues = question.querySelectorAll(`li:not([aria-labelledby=${ currentId }])`)
  let count = 0
  unselectedOptionsValues.forEach((option) => option.hidden = true )
  selectedOptionValues.forEach((option) => {
    option.hidden = false
    count ++
  } )
  question.dataset.moreFive = count > 5
}

export default { init }