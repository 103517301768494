/**
 * Toggle open/close
 */

const TOGGLE_SEL = '[data-toggle]';

function init() {
  const body = document.querySelector('body');
  body.addEventListener("click",(event) => {
    if (event.target && event.target.matches(TOGGLE_SEL)) {
      toggleBlock(event.target)
    }
  });
}

function toggleBlock(toggle, reveal) {
  const id = toggle.getAttribute('aria-controls')
  const buttons = document.querySelectorAll(`[aria-controls=${id}]`)
  const panel = document.getElementById(id);
  const shouldReveal = reveal || panel.hidden;
  buttons.forEach((button)=> button.setAttribute('aria-expanded', shouldReveal ? 'true' : 'false'))
  panel.hidden = !shouldReveal;
}

export default { init }
