/**
 * Infography with infogram library
 */

const INFOGRAPHY_SEL = '[data-infography]';


function init() {
  const infography = document.querySelector(INFOGRAPHY_SEL);
  if (infography) {
    !function(e,i,n,s){var t="InfogramEmbeds",d=e.getElementsByTagName("script")[0];if(window[t]&&window[t].initialized)window[t].process&&window[t].process();else if(!e.getElementById(n)){var o=e.createElement("script");o.async=1,o.id=n,o.src="https://e.infogram.com/js/dist/embed-loader-min.js",d.parentNode.insertBefore(o,d)}}(document,0,"infogram-async");
  }
}

export default { init }
