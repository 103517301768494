/**
 * Expan open/close
 */

const TOGGLE_SEL = '[data-expand]';

function init() {

  const body = document.querySelector('body');
  const toggles = document.querySelectorAll(TOGGLE_SEL);

  if(toggles) {
    toggles.forEach((toggle)=> toggleBlock(toggle, false))
    body.addEventListener("click",(event) => {
      if (event.target && event.target.matches(TOGGLE_SEL)) {
        toggleBlock(event.target)
      }
    });
  }

}

function toggleBlock(toggle, reveal) {
  const id = toggle.getAttribute('aria-controls')
  const buttons = document.querySelectorAll(`[aria-controls=${id}]`)
  const panel = document.getElementById(id);
  const shouldReveal = reveal || panel.dataset.expand == 'false';
  buttons.forEach((button)=> button.setAttribute('aria-expanded', shouldReveal ? 'true' : 'false'))
  panel.dataset.expand = shouldReveal ? 'true' : 'false'
  panel.style.height = shouldReveal ? 'auto' : '300px'
}

export default { init }
