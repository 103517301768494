/**
 * Tabs: this js is only for magicLine,
 * tabs panel works with dependency library : van11y-accessible-tab-panel-aria
 * https://van11y.net/fr/onglets-accessibles/
 */


const MAGIGLINE_SEL = '[data-tabs-magicline]';
const TAB_SEL = '[data-tabs]';
const ACTIVE_SEL = '[data-tabs][aria-selected="true"], .Tabs-item--selected';
const PADDING = 40;

function init() {

  const magicLine = document.querySelector(MAGIGLINE_SEL);
  if (magicLine) {
    const tabs = document.querySelectorAll(TAB_SEL);
    window.onload = defaultMagicLine;
    tabs.forEach((tab) => {
      tab.addEventListener('mouseenter', function () {
        const width = tab.offsetWidth - PADDING*2 + 10
        const left = tab.offsetLeft + PADDING - 5
        magicLine.style.width = `${width}px`;
        magicLine.style.left = `${left}px`;
      });
      tab.addEventListener('mouseleave', function () {
        defaultMagicLine()
      });
    });
  }

  function defaultMagicLine() {
    const tab = document.querySelector(ACTIVE_SEL);
    const width = tab.offsetWidth - PADDING*2 + 10
    const left = tab.offsetLeft + PADDING - 5
    magicLine.style.width = `${width}px`;
    magicLine.style.left = `${left}px`;
  }

}


export default { init }
