/**
 *  Scroll to Top button appear
 */

const SCROLL_SEL = '[data-scrolltop]';

function init() {

  const scrollTop = document.querySelector(SCROLL_SEL);
  if (scrollTop) {
    toggleScroll(scrollTop)
    window.addEventListener('scroll', () =>  toggleScroll(scrollTop));
  }

}

function toggleScroll(scrollTop) {
  let scrollValue = window.pageYOffset ? window.pageYOffset : 0;

  if (scrollValue <= 400) {
    scrollTop.style.opacity = '0';
  } else {
    scrollTop.style.opacity = '1';
  }
}

export default { init }

